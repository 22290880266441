import React from 'react'
import { graphql } from 'gatsby'

import Contact from './_contact'

export default props => <Contact {...props} />

export const pageQuery = graphql`
  query {
    site {
      ...SiteInformation
    }
    prismic {
      allContacts(lang: "fr-fr") {
        edges {
          node {
            meta_title
            meta_keywords
            meta_description
            page_subtitle
            content_body
            form_label_name
            form_label_email
            form_label_message
            form_send_button
            form_success_message
            form_error_message
            form_required_field_message
            socials_text
            _meta {
              id
            }
          }
        }
      }
    }
  }
`
