import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useForm, useField } from 'react-final-form-hooks'
import { Button, Input, InputGroup, TextArea } from 'sancho'
import { TiMail } from 'react-icons/ti'
import { TABLET_MEDIA_QUERY } from 'typography-breakpoint-constants'
import { RichText } from 'prismic-reactjs'

import { rhythm } from '../utils/typography'
import Layout from '../components/layout'
import SocialNetworks from '../components/social-networks'
import { linkResolver } from '../utils/link-resolver'
import htmlSerializer from '../utils/html-serializer'

const Box = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: row;

  ${TABLET_MEDIA_QUERY} {
    flex-direction: column;
  }
`

const Content = styled.div`
  width: 100%;
  flex-basis: 50%;
  text-align: justify;
  font-size: ${rhythm(0.7)};
  margin-top: -${rhythm(4)};
  padding-right: ${rhythm(1)};
  order: 1;

  ${TABLET_MEDIA_QUERY} {
    order: 0;
    margin-top: 0;
    text-align: center;
    padding-right: 0;
    margin-bottom: ${rhythm(2)};
  }
`
const FormContainer = styled.div`
  flex-basis: 50%;
  width: 100%;
  text-align: center;
  padding-left: ${rhythm(2)};
  padding-right: ${rhythm(2)};
  justify-content: center;
  display: flex;
  order: 0;

  form {
    width: 80%;
    font-family: Raleway;
  }

  ${TABLET_MEDIA_QUERY} {
    order: 1;
  }
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

function Contact({ data: { site, prismic } }) {
  const {
    meta_title,
    meta_description,
    meta_keywords,
    page_subtitle,
    content_body,
    form_label_name,
    form_label_email,
    form_label_message,
    form_send_button,
    form_success_message,
    form_error_message,
    form_required_field_message,
    socials_text,
  } = prismic.allContacts.edges[0].node

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const onSubmit = values => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        ...values,
      }),
    })
      .then(() => {
        setSuccess(true)
        setError(false)
        form.reset()
      })
      .catch(error => {
        setSuccess(false)
        setError(error)
      })
  }

  const validate = values => {
    const errors = {}
    if (!values.name || values.name.trim() === '') {
      errors.name = RichText.asText(form_required_field_message)
    }
    if (!values.email || values.email.trim() === '') {
      errors.email = RichText.asText(form_required_field_message)
    }

    if (!values.message || values.message.trim() === '') {
      errors.message = RichText.asText(form_required_field_message)
    }
    return errors
  }

  const { form, handleSubmit, values, pristine, submitting } = useForm({
    onSubmit,
    validate,
  })
  const name = useField('name', form)
  const email = useField('email', form)
  const message = useField('message', form)

  return (
    <Layout
      title={RichText.asText(meta_title)}
      subtitle={RichText.asText(page_subtitle)}
      site={site}
      customDescription={
        meta_description ? RichText.asText(meta_description) : null
      }
      customKeywords={meta_description ? RichText.asText(meta_keywords) : null}
    >
      <Box>
        <FormContainer>
          <form
            name="contact"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <InputGroup
              error={name.meta.touched && name.meta.error}
              label={RichText.asText(form_label_name)}
            >
              <Input {...name.input} />
            </InputGroup>
            <InputGroup
              error={email.meta.touched && email.meta.error}
              label={RichText.asText(form_label_email)}
            >
              <Input {...email.input} />
            </InputGroup>
            <InputGroup
              error={message.meta.touched && message.meta.error}
              label={RichText.asText(form_label_message)}
            >
              <TextArea {...message.input} />
            </InputGroup>
            <Button
              css={{
                marginTop: rhythm(1),
              }}
              variant="outline"
              type="submit"
              disabled={pristine || submitting}
              iconAfter={
                <div>
                  <TiMail size={rhythm(1)} css={{ marginTop: '5px' }} />
                </div>
              }
            >
              {RichText.asText(form_send_button)}
            </Button>
          </form>
        </FormContainer>
        <Content>
          <div>
            {!success &&
              !error && <RichText
                render={content_body}
                linkResolver={linkResolver}
                htmlSerializer={htmlSerializer}
            />}
          </div>
          <p>
            {success && <span>{RichText.asText(form_success_message)}</span>}
            {error && <span>{RichText.asText(form_error_message)}</span>}
          </p>
          <div style={{ textAlign: 'center', marginTop: rhythm(2) }}>
            {RichText.asText(socials_text)}
            <SocialNetworks style={{ marginTop: 10 }} />
          </div>
        </Content>
      </Box>
    </Layout>
  )
}

export default Contact
