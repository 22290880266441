import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { SocialIconLink } from './ui/social-link'
import { mq } from '../utils/responsive'
import { rhythm } from '../utils/typography'

const SocialNetworks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: ${rhythm(0.5)};

  ${mq.small(css`
    justify-content: space-evenly;
    ${SocialIconLink} {
      .social-container {
        position: relative;
      }
    }
  `)};
`

const networks = [
  { url: 'https://www.facebook.com/Karalabelparis/' },
  { url: 'https://www.youtube.com/channel/UC-mm2wp10BA0ZkC21gXJ9cg' },
  { url: 'https://twitter.com/karalabelparis' },
  { url: 'https://www.pinterest.fr/karalabel/' },
  { url: 'https://www.linkedin.com/in/eloisegk' },
  { url: 'https://medium.com/@karalabelparis' },
  { url: 'https://www.instagram.com/karalabel' },
]

export default ({ style, linkFontSize }) => (
  <SocialNetworks style={style}>
    {networks.map((n, index) => (
      <SocialIconLink {...n} style={{ fontSize: linkFontSize }} key={index} />
    ))}
  </SocialNetworks>
)
