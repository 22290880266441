import { css, keyframes } from '@emotion/core'
import { SocialIcon } from 'react-social-icons'
import styled from '@emotion/styled'

import { swing } from '../../utils/animations'
import { mq } from '../../utils/responsive'

const socialIconAnimation = keyframes`${swing}`

export const SocialIconLink = styled(SocialIcon)`
  margin-left: 5px;
  margin-right: 5px;
  transition: animation 1s linear;

  ${mq.small(css`
    width: 40px !important;
    height: 40px !important;
  `)} &:hover {
    animation: 1s ${socialIconAnimation} infinite;
  }
`
