function rotate3d(a, b, c, d) {
  return 'rotate3d(' + a + ', ' + b + ', ' + c + ', ' + d + 'deg)'
}

export const swing = {
  from: {
    transform: rotate3d(0, 0, 0, 0),
  },
  '20%': {
    transform: rotate3d(0, 0, 1, 15),
  },
  '40%': {
    transform: rotate3d(0, 0, 1, -10),
  },
  '60%': {
    transform: rotate3d(0, 0, 1, 5),
  },
  '80%': {
    transform: rotate3d(0, 0, 1, -5),
  },
  to: {
    transform: rotate3d(0, 0, 0, 0),
  },
}
